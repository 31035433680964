import React from "react"

// Libraries
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoint from "utils/breakpoints"

// Components
import Container from "components/container"

const StyledHelpEmployees = styled.div`
  display: flex;
  padding: 64px 0;
  background-color: ${colors.silver__pale};

  ${breakpoint.medium`
  padding-top: 150px;
  padding-bottom: 175px;
  `}

  .heading_section {
    margin-bottom: 32px;

    ${breakpoint.medium`
      margin-bottom: 78px;
    `}
    h2 {
      ${breakpoint.medium`
      font-size: 48px;
      line-height: 57px;
    `}
    }
  }
  .items {
    .item {
      margin-bottom: 24px;

      ${breakpoint.medium`
      margin-bottom: 89px;
    `}

      &:last-child {
        margin-bottom: 0px;
      }

      .item-wrapper {
        min-height: 458px;
        background: white;
        box-shadow: 2px 16px 40px rgba(110, 200, 205, 0.2);
        border-radius: 20px;
        padding: 24px;

        ${breakpoint.medium`
        padding: 56px 24px;
      `}
      }

      &:nth-child(even) {
        ${breakpoint.medium`
        .content {
          order: 1;
        }
      `}
      }

      &:nth-child(odd) {
        ${breakpoint.medium`
        .image {
          order: 1;
        }
      `}
      }

      .content {
        order: 0;
      }

      .image {
        order: 1;
        max-width: 543px;
      }
    }

    &:last-child {
      margin-bottom: 0px;
    }
  }
`

const HelpEmployees = () => {
  const { mobilityImage, teanureImage, riskImage } = useStaticQuery(graphql`
    query {
      mobilityImage: file(
        relativePath: { eq: "talent-mobility/help-your-employees-image-1.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 384, quality: 100)
        }
      }
      teanureImage: file(
        relativePath: { eq: "talent-mobility/help-your-employees-image-2.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 352, quality: 100)
        }
      }
      riskImage: file(
        relativePath: { eq: "talent-mobility/help-your-employees-image-3.png" }
      ) {
        childImageSharp {
          gatsbyImageData(width: 445, quality: 100)
        }
      }
    }
  `)

  const data = [
    {
      title: "Mobility Suggestions",
      content:
        "Gain actionable, data-backed mobility predictions for every employee to align their growth goals with your demand for people and skills.",
      icon: mobilityImage,
    },
    {
      title: "Flight Risk Prediction",
      content:
        "Predict employee departure risk and proactively intervene to prevent churn among high-value employees.",
      icon: riskImage,
    },
    {
      title: "Tenure Analytics",
      content:
        " Identify critical tenure points to mitigate and manage potential workforce gaps before they negatively impact your organization.",
      icon: teanureImage,
    },
  ]
  return (
    <StyledHelpEmployees id="employees-thrive">
      <Container>
        <div className="row justify-content-center">
          <div className="heading_section col-12 col-md-8">
            <h2 className="h2 text-center color--navy__extra_dark">
              Help Your Employees{" "}
              <span className="color--turquoise__primary">Thrive</span>
            </h2>
            <p className="text--large mt-4 text-center">
              Arena’s Talent Mobility helps you leverage your employees’ desire
              for advancement to stabilize your workforce, minimize flight risk,
              and improve long-term retention.
              <br />
              <br />
              <span className="h3 font-weight-700">
                Arena Talent Mobility includes the following key components:
              </span>
            </p>
          </div>
          <div className="row">
            <div className="items">
              {data.map((item) => (
                <div className="item col-12" key={item.title}>
                  <div className="item-wrapper d-flex align-items-center">
                    <div className="row align-items-center justify-content-center">
                      <div className="image col-12 col-md-5 text-center">
                        <GatsbyImage image={getImage(item.icon)} alt="" />
                      </div>
                      <div className="content col-12 col-md-5 offset-sm-1 mb-4 mb-sm-0">
                        <div className="row">
                          <div className="col-12 col-sm-10">
                            <h2 className="h2 mb-2">{item.title}</h2>
                            <p className="text--large mb-4 color--navy__extra_dark">
                              {item.content}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </Container>
    </StyledHelpEmployees>
  )
}

export default HelpEmployees
