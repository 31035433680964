import React from "react"

// Layout
import Layout from "layouts/layout-primary"

// Components
import SEO from "components/seo/"
import Hero from "sections/talent-mobility/hero"
import HelpEmployees from "sections/talent-mobility/help-employees"

// Sections

const TalentMobility = () => (
  <Layout showNewsletter={false}>
    <SEO title="Talent Mobility | Arena Analytics" />
    <Hero />
    <HelpEmployees />
  </Layout>
)

export default TalentMobility
