import React from "react"

// Libraries
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables"
import breakpoints from "utils/breakpoints"

// Components
import Container from "components/container"
import HeroArrow from "components/hero-arrow"

const StyledHero = styled.section`
  height: 100%;
  padding-top: 35px;
  padding-bottom: 105px;
  position: relative;

  .text {
    margin-bottom: 32px;

    ${breakpoints.medium`
    margin-bottom: 0px;
    `}
  }

  .hero__arrow {
    top: calc(100% - 14px);

    button {
      background-color: ${colors.navy__extra_dark} !important;

      svg {
        * {
          stroke: ${colors.white};
        }
      }

      &:hover {
        background-color: ${colors.turquoise__primary} !important;
      }
    }

    .arrow__illustration {
      * {
        fill: ${colors.white};
      }
    }
  }
`

const Hero = () => (
  <StyledHero>
    <Container>
      <div className="row">
        <div className="col-12 col-md-6 d-flex justify-content-center align-items-center">
          <div>
            {" "}
            <h1 className="h1 maxi mb-4">
              <span className="color--turquoise__primary">Mobilize</span> Your
              Existing Talent
            </h1>
            <p className="text text--large text-center text-md-start">
              Empower your talent to grow with you
            </p>
          </div>
        </div>
        <div className="col-12 col-md-6 d-flex">
          {" "}
          <StaticImage
            src="../../assets/images/talent-mobility/talent-mobility-hero.png"
            alt=""
            className="image"
          />
        </div>
      </div>
      <HeroArrow link="employees-thrive" className="hero__arrow" />
    </Container>
  </StyledHero>
)

export default Hero
